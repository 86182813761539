<template>
  <product title="Amazfit GTS 3"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="899"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://item.jd.com/100014760129.html"
  ></product>
</template>

<script>
import Product from '../Product'

export default {
  name: 'Amazfit-GTS-3',
  components: {
    Product
  },
  data () {
    return {
      slogan: '8.8mm 轻薄时尚设计｜全方位健康监测｜Zepp OS丰富手表小程序生态｜12 天超长续航',
      colors: [
        '曜石黑',
        '薄雾白',
        '柔砂棕'
      ],
      swiperData: [
        [
          'https://site-cdn.huami.com/files/amazfit/cn/gts3/1_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gts3/1_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gts3/1_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gts3/1_pro_04.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/gts3/2_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gts3/2_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gts3/2_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gts3/2_pro_04.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/gts3/3_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gts3/3_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gts3/3_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/cn/gts3/3_pro_04.png'
        ]
      ],
      relativedProducts: [
      ],
      detailImages: [
        'https://site-cdn.huami.com/files/amazfit/cn/gts3/GTS3_1.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gts3/GTS3_2.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gts3/GTS3_3.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gts3/GTS3_4.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gts3/3-5-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gts3/GTS3_6.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gts3/GTS3_7.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gts3/GTS3_8.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gts3/GTS3.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gts3/GTS3_10.jpg'
      ],
      paramImages: [
        'https://site-cdn.huami.com/files/amazfit/cn/gts3/GTS3_11.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gts3/GTS3_12.jpg'
      ]
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
